import Link from "next/link";
import { Icon } from "./Icon";
import { Icons } from "./Icons";

export enum AppErrorMode {
  NotFound = "not-found",
  Error = "error",
}
export type AppErrorProps = {
  mode?: AppErrorMode;
  message?: string;
  title?: string;
};

const MODE_TITLES = {
  [AppErrorMode.NotFound]: "You might be lost!",
  [AppErrorMode.Error]: "Something went wrong!",
};

const MODE_MESSAGES = {
  [AppErrorMode.NotFound]:
    "Not what you're looking for... Don't worry - you have our support!",
  [AppErrorMode.Error]: "Please try again later.",
};

// TODO(DEV-330) extract svg into our own icon library rather than hardcoding
export const NextAppError = (props: AppErrorProps) => {
  const { mode = AppErrorMode.NotFound } = props;

  const title = props.title || MODE_TITLES[mode];
  const message = props.message || MODE_MESSAGES[mode];

  return (
    <div className="flex w-full flex-auto flex-col items-center justify-center gap-4">
      <div
        className="text-center text-5xl font-bold"
        data-testid="app-error-title"
      >
        {title}
      </div>
      <div
        className="text-center text-gray-800"
        data-testid="app-error-message"
        dangerouslySetInnerHTML={{ __html: message }}
      />
      <svg
        width="234"
        height="181"
        viewBox="0 0 234 181"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M205.674 176.346C196.326 181.399 184.705 176.599 176.621 169.778C168.537 162.957 158.684 150.325 150.347 144.01C136.453 133.652 115.989 132.894 101.337 141.988C92.4947 147.294 85.9263 155.631 78.8526 163.21C71.779 170.788 63.4421 177.862 53.3368 179.631C41.2105 181.904 28.3263 175.841 21.5053 165.483C14.6842 155.378 13.421 141.988 16.9579 130.115C20.7474 118.494 29.0842 108.388 39.1895 101.567C48.0316 95.5042 59.4 89.9464 60.9158 79.5885C62.1789 70.4937 55.8632 63.9253 52.3263 56.0937C48.7895 48.5148 46.5158 40.1779 45.5053 31.8411C44.7474 25.7779 44.7474 19.4622 47.7789 14.4095C53.0842 5.56741 66.4737 4.05162 75.5684 9.35689C84.6632 14.6621 89.9684 24.5148 93.2526 34.3674C94.5158 38.1569 95.5263 41.9464 97.5474 45.2306C99.5684 48.5148 102.6 51.5464 106.389 52.3042C110.432 53.0621 114.726 51.2937 117.758 48.2621C122.558 43.4621 124.326 36.1358 127.611 30.0727C131.4 23.5043 135.695 16.9358 141.253 11.6306C148.832 4.55689 158.684 -0.243114 169.042 0.00951756C179.4 0.262149 189.758 6.57794 193.295 16.1779C196.832 25.7779 193.295 36.3885 187.737 44.9779C182.179 53.5674 174.6 60.6411 169.042 68.9779C163.232 77.3148 159.442 87.9253 162.221 97.5253C166.516 105.357 174.6 107.883 183.947 115.462C193.295 123.041 207.189 133.904 212.495 144.515C218.053 155.378 216.284 170.536 205.674 176.346Z"
          fill="#D1E4FC"
        />
        <path
          d="M18.7263 97.5253C18.7263 98.5358 17.7158 99.2937 16.7053 99.2937H13.9263V102.073C13.9263 103.083 12.9158 103.841 11.9053 103.841C10.8947 103.841 10.1368 103.083 10.1368 101.82V99.0411H7.3579C6.34737 99.0411 5.58948 98.0306 5.58948 97.02C5.58948 96.0095 6.34737 95.2516 7.61053 95.2516H10.3895V92.4727C10.3895 91.4622 11.4 90.7043 12.4105 90.7043C13.4211 90.7043 14.179 91.7148 14.179 92.7253V95.5043H16.9579C17.9684 95.5043 18.7263 96.5148 18.7263 97.5253Z"
          fill="#D1E4FC"
        />
        <path
          d="M105.884 173.82C105.884 174.831 104.874 175.588 103.863 175.588H101.084V178.367C101.084 179.378 100.074 180.136 99.0632 180.136C98.0526 180.136 97.2947 179.378 97.2947 178.115V175.336H94.5158C93.5053 175.336 92.7474 174.325 92.7474 173.315C92.7474 172.304 93.5053 171.546 94.7684 171.546H97.5474V168.767C97.5474 167.757 98.5579 166.999 99.5684 166.999C100.579 166.999 101.337 168.01 101.337 169.02V171.799H104.116C105.126 171.799 105.884 172.81 105.884 173.82Z"
          fill="#D1E4FC"
        />
        <path
          d="M185.211 61.9043V144.767H50.5579V61.9043C50.5579 57.1043 54.6 53.3148 59.4 53.3148H176.621C181.421 53.3148 185.211 57.1043 185.211 61.9043Z"
          fill="white"
        />
        <path
          d="M185.211 60.6411V143.504H179.147V60.6411C179.147 55.8411 175.358 52.0516 170.558 52.0516H176.621C181.421 52.0516 185.211 55.8411 185.211 60.6411Z"
          fill="#D1E4FC"
        />
        <path
          d="M185.211 143.757C185.211 152.346 178.137 159.167 174.6 159.167H39.6947C26.0526 159.167 19.2316 152.094 19.2316 143.757H185.211Z"
          fill="white"
        />
        <path
          d="M185.211 143.757C185.211 144.262 185.211 144.515 185.211 145.02C184.705 153.104 177.884 159.42 169.8 159.42H136.958C128.874 159.42 122.053 153.104 121.547 145.02C121.547 144.515 121.547 144.262 121.547 143.757H185.211Z"
          fill="#D1E4FC"
        />
        <g opacity="0.3">
          <path
            d="M152.621 132.641C152.621 136.431 153.379 140.22 154.389 143.757C154.642 144.262 154.642 144.515 154.895 145.02C156.663 149.567 159.189 153.357 162.726 156.641H178.389C182.179 154.115 184.958 149.82 185.211 145.02V98.2832C167.021 99.5464 152.621 114.452 152.621 132.641Z"
            fill="#A3C9F9"
          />
        </g>
        <path
          d="M149.084 92.9779L147.316 107.125C147.316 107.883 146.305 108.136 145.8 107.631L134.432 99.0411C133.926 98.5358 133.926 97.7779 134.684 97.5253L139.989 95.2516C136.2 86.1569 127.358 80.0937 117.253 80.0937C107.147 80.0937 98.3053 85.9042 94.5158 95.2516C94.2632 96.0095 93.5053 96.5148 92.7474 96.5148C92.4947 96.5148 92.2421 96.5148 91.9895 96.2621C90.9789 96.0095 90.4737 94.7464 90.979 93.7358C95.2737 83.1253 105.379 76.3042 117.253 76.3042C128.621 76.3042 138.979 83.1253 143.274 93.7358L147.821 91.9674C148.579 91.7148 149.337 92.22 149.084 92.9779Z"
          fill="#D1E4FC"
        />
        <path
          d="M145.042 114.957C140.747 125.567 130.642 132.388 118.768 132.388C107.4 132.388 97.0421 125.567 92.7474 114.957V114.704L87.9474 116.725C87.1895 116.978 86.6842 116.473 86.6842 115.715L88.4526 101.567C88.4526 100.81 89.4632 100.557 89.9684 101.062L101.337 109.652C101.842 110.157 101.842 110.915 101.084 111.167L96.0316 113.188V113.441C99.8211 122.536 108.663 128.599 118.516 128.599C128.621 128.599 137.716 122.788 141.253 113.441C141.505 112.431 142.768 111.925 143.779 112.431C144.789 112.936 145.295 113.946 145.042 114.957Z"
          fill="#D1E4FC"
        />
        <path
          d="M215.021 132.641C215.021 147.294 202.895 159.167 187.737 159.167C172.579 159.167 160.453 147.294 160.453 132.641C160.453 117.988 172.579 106.115 187.737 106.115C202.642 106.115 215.021 117.988 215.021 132.641Z"
          fill="white"
        />
        <path
          d="M215.021 132.641C215.021 147.294 202.895 159.167 187.737 159.167C186.474 159.167 184.958 159.167 183.695 158.915C196.832 157.146 206.937 146.031 206.937 132.641C206.937 119.252 196.832 108.136 183.695 106.367C184.958 106.115 186.221 106.115 187.737 106.115C202.642 106.115 215.021 117.988 215.021 132.641Z"
          fill="#D1E4FC"
        />
        <path
          d="M202.895 142.494C202.895 142.999 202.895 143.252 202.642 143.757C202.137 147.041 199.105 148.557 195.821 147.799C182.937 145.273 174.347 136.431 172.326 124.304C171.821 121.02 173.337 117.988 176.368 117.483C176.874 117.483 177.126 117.483 177.632 117.231C178.642 116.978 179.653 117.736 179.905 118.746C180.411 121.02 181.168 123.294 182.179 125.315C182.684 126.325 182.432 127.588 181.421 128.094C180.916 128.346 180.663 128.599 180.158 128.852C179.147 129.357 178.895 130.62 179.653 131.631C181.926 134.915 184.958 137.946 188.242 140.22C189.253 140.978 190.516 140.725 191.021 139.715L191.779 138.452C192.284 137.441 193.547 137.188 194.558 137.694C196.579 138.704 198.853 139.462 201.126 139.967C202.389 140.473 203.147 141.483 202.895 142.494Z"
          fill="#D1E4FC"
        />
        <path
          d="M61.1684 27.5464L63.6947 27.799L63.4421 30.3253C63.4421 31.3358 64.2 32.3464 65.2105 32.3464C66.2211 32.3464 67.2316 31.5885 67.2316 30.5779L67.4842 28.0516L70.0105 28.3043C71.0211 28.3043 72.0316 27.5464 72.0316 26.5358C72.0316 25.5253 71.2737 24.5148 70.2632 24.5148L67.7368 24.2621L67.9895 21.7358C67.9895 20.7253 67.2316 19.7148 66.2211 19.7148C65.2105 19.7148 64.2 20.4727 64.2 21.4832L63.9474 24.0095L61.4211 23.7569C60.4105 23.7569 59.4 24.5148 59.4 25.5253C59.4 26.5358 60.1579 27.2937 61.1684 27.5464Z"
          fill="#0E498F"
        />
        <path
          d="M220.074 24.2621H217.295V21.4832C217.295 20.4727 216.537 19.7148 215.526 19.4621C214.516 19.4621 213.758 20.22 213.505 21.2306V24.0095H210.726C209.716 24.0095 208.958 24.7674 208.705 25.7779C208.705 26.7885 209.463 27.5464 210.474 27.799H213.253V30.5779C213.253 31.5885 214.011 32.3464 215.021 32.599C216.032 32.599 216.789 31.8411 217.042 30.8306V28.0516H219.821C220.832 28.0516 221.589 27.2937 221.842 26.2832C221.842 25.2727 221.084 24.5148 220.074 24.2621Z"
          fill="#0E498F"
        />
        <path
          d="M6.85263 156.388H3.31579C1.8 156.388 0.789474 157.399 0.789474 158.915C0.789474 160.431 1.8 161.441 3.31579 161.441H6.85263C8.36842 161.441 9.37895 160.431 9.37895 158.915C9.37895 157.399 8.36842 156.388 6.85263 156.388Z"
          fill="#0E498F"
        />
        <path
          d="M230.684 156.641H225.884C224.368 156.641 223.358 157.652 223.358 159.167C223.358 160.683 224.368 161.694 225.884 161.694H230.684C232.2 161.694 233.211 160.683 233.211 159.167C233.211 157.904 232.2 156.641 230.684 156.641Z"
          fill="#0E498F"
        />
        <path
          d="M106.642 105.357C107.4 104.852 107.905 104.346 108.411 104.094C108.916 103.841 109.168 103.336 109.674 102.831C109.926 102.325 110.179 101.82 110.179 101.062C110.179 100.557 109.926 99.799 109.674 99.5464C109.421 99.0411 108.916 98.7885 108.411 98.5358C107.905 98.2832 107.4 98.0306 106.642 98.0306C105.632 98.0306 104.621 98.2832 103.611 98.7885C103.358 99.0411 103.105 99.2937 103.105 99.5464C103.105 99.799 103.105 100.052 103.358 100.052C103.611 100.304 103.863 100.304 104.116 100.304C104.874 100.052 105.632 99.799 106.389 99.799C107.147 99.799 107.653 100.052 107.905 100.304C108.158 100.557 108.411 101.062 108.411 101.567C108.411 101.82 108.411 102.073 108.158 102.325C107.905 102.578 107.653 102.831 107.4 103.083C107.147 103.336 106.642 103.588 106.137 104.094C105.379 104.599 104.874 104.852 104.621 105.357C104.116 105.61 103.863 106.115 103.611 106.62C103.358 107.125 103.105 107.631 103.105 108.136C103.105 108.388 103.105 108.641 103.358 108.894C103.611 109.146 103.863 109.146 104.116 109.146H109.168C109.421 109.146 109.674 109.146 109.674 108.894C109.926 108.641 109.926 108.641 109.926 108.388C109.926 108.136 109.926 107.883 109.674 107.883C109.421 107.631 109.421 107.631 109.168 107.631H104.874C104.874 107.378 105.126 107.378 105.379 107.125C105.632 106.367 106.137 105.862 106.642 105.357Z"
          fill="#0E498F"
        />
        <path
          d="M118.263 104.599H117.505V99.2937C117.505 99.0411 117.505 98.7885 117.253 98.5358C117 98.2832 116.747 98.2832 116.495 98.2832H116.242C115.989 98.2832 115.989 98.2832 115.737 98.2832C115.484 98.2832 115.484 98.5358 115.484 98.5358L111.189 104.346C110.937 104.599 110.937 104.852 110.937 105.104V105.357C110.937 105.61 110.937 105.862 111.189 106.115C111.442 106.367 111.695 106.367 111.947 106.367H115.737V108.136C115.737 108.388 115.737 108.641 115.989 108.641C116.242 108.894 116.242 108.894 116.495 108.894C116.747 108.894 117 108.894 117 108.641C117.253 108.388 117.253 108.388 117.253 108.136V106.367H118.263C118.516 106.367 118.768 106.367 118.768 106.115C119.021 105.862 119.021 105.862 119.021 105.61C119.021 105.357 119.021 105.104 118.768 105.104C118.516 104.599 118.516 104.599 118.263 104.599ZM115.737 104.599H112.705L115.737 100.557V104.599Z"
          fill="#0E498F"
        />
        <path
          d="M123.568 97.2727C123.568 97.2727 123.316 97.5253 123.316 97.7779L119.779 108.894V109.146C119.779 109.399 119.779 109.652 120.032 109.652C120.284 109.904 120.284 109.904 120.537 109.904C120.789 109.904 120.789 109.904 121.042 109.652C121.042 109.652 121.295 109.399 121.295 109.146L124.832 98.0306V97.7779C124.832 97.5253 124.832 97.2727 124.579 97.2727C124.326 97.2727 123.821 97.2727 123.568 97.2727Z"
          fill="#0E498F"
        />
        <path
          d="M131.905 98.2832H126.347C126.095 98.2832 125.842 98.2832 125.842 98.5358C125.589 98.7885 125.589 98.7885 125.589 99.0411C125.589 99.2937 125.589 99.5464 125.842 99.5464C126.095 99.799 126.095 99.799 126.347 99.799H130.895L127.358 107.378C127.358 107.631 127.358 107.631 127.358 107.883C127.358 108.136 127.358 108.388 127.611 108.388C127.863 108.641 127.863 108.641 128.116 108.641C128.368 108.641 128.368 108.641 128.621 108.388C128.874 108.388 128.874 108.136 128.874 108.136L132.663 99.799C132.663 99.5464 132.916 99.2937 132.916 99.0411C132.916 98.7885 132.916 98.5358 132.663 98.2832C132.411 98.2832 132.158 98.2832 131.905 98.2832Z"
          fill="#0E498F"
        />
        <path
          d="M217.547 132.641C217.547 116.725 204.158 103.588 187.737 103.588V60.6411C187.737 54.5779 182.684 49.5253 176.368 49.5253H59.4C53.0842 49.5253 48.0316 54.5779 48.0316 60.6411V140.978H18.979C17.4632 140.978 16.4526 141.988 16.4526 143.504C16.4526 148.557 18.4737 153.104 22.2632 156.388H18.4737C16.9579 156.388 15.9474 157.399 15.9474 158.915C15.9474 160.431 16.9579 161.441 18.4737 161.441C51.3158 161.441 211.232 161.694 211.484 161.694C213 161.694 214.011 160.683 214.011 159.167C214.011 157.652 213 156.641 211.484 156.641H204.158C212.242 151.336 217.547 142.494 217.547 132.641ZM53.0842 60.6411C53.0842 57.3569 55.8632 54.5779 59.4 54.5779H176.621C179.905 54.5779 182.937 57.3569 182.937 60.6411V103.841C180.158 104.346 177.379 105.104 174.853 106.367V64.9358C174.853 64.1779 174.6 63.6727 174.095 63.1674C173.589 62.6622 172.832 62.4095 172.326 62.4095L63.9474 62.6622C62.4316 62.6622 61.4211 63.6727 61.4211 65.1885V141.231H53.3368V60.6411H53.0842ZM170.305 156.641H136.2C130.389 156.136 125.337 151.841 124.326 146.283H161.211C163.484 150.325 166.768 154.115 170.811 156.641C170.558 156.641 170.558 156.641 170.305 156.641ZM66.2211 140.978V67.7148L169.547 67.4621V109.399C159.695 116.725 155.4 129.104 158.937 140.978C31.8632 141.231 75.8211 140.978 66.2211 140.978ZM21.7579 146.283H55.6105L56.6211 148.81C56.8737 149.82 58.1368 150.578 59.1474 150.578H84.6632C85.9263 150.578 86.9368 149.82 87.1895 148.81L88.2 146.283H118.768C119.274 150.325 121.295 153.862 124.074 156.641H34.3895C27.8211 155.631 23.0211 151.841 21.7579 146.283ZM188.495 156.641H186.979C173.589 156.136 162.979 145.525 162.979 132.641C162.979 119.504 174.095 108.641 187.737 108.641C201.379 108.641 212.495 119.504 212.495 132.641C212.495 145.525 201.632 156.136 188.495 156.641Z"
          fill="#0E498F"
        />
        <path
          d="M180.158 118.746C179.905 117.736 178.895 116.978 177.884 117.231L176.874 117.483C177.379 121.525 178.895 125.315 180.916 128.852C181.168 128.599 181.421 128.599 181.674 128.346C182.684 127.841 182.937 126.578 182.432 125.567C181.421 123.294 180.663 121.273 180.158 118.746Z"
          fill="#0E498F"
        />
        <path
          d="M201.379 140.22C199.105 139.715 196.832 138.957 194.811 137.946C193.8 137.441 192.537 137.694 192.032 138.704L191.526 139.462C194.811 141.483 198.6 142.999 202.895 143.504C202.895 143.252 202.895 142.999 203.147 142.494C203.147 141.483 202.389 140.473 201.379 140.22Z"
          fill="#0E498F"
        />
        <path
          d="M192.284 128.599V131.125C192.284 131.378 192.537 131.631 192.789 131.631H194.558C194.811 131.631 195.063 131.378 195.063 131.125V128.599C195.063 128.094 195.568 127.841 196.074 128.094L198.347 129.357C198.6 129.61 199.105 129.357 199.105 129.104L199.863 127.588C200.116 127.336 199.863 126.831 199.611 126.831L197.337 125.567C196.832 125.315 196.832 124.81 197.337 124.557L199.611 123.294C199.863 123.041 200.116 122.788 199.863 122.536L199.105 121.02C198.853 120.767 198.6 120.515 198.347 120.767L196.074 122.031C195.568 122.283 195.063 122.031 195.063 121.525V118.746C195.063 118.494 194.811 118.241 194.558 118.241H192.789C192.537 118.241 192.284 118.494 192.284 118.746V121.273C192.284 121.778 191.779 122.031 191.274 121.778L189 120.515C188.747 120.262 188.242 120.515 188.242 120.767L187.484 122.283C187.232 122.536 187.484 123.041 187.737 123.041L190.011 124.304C190.516 124.557 190.516 125.062 190.011 125.315L187.737 126.578C187.484 126.831 187.232 127.083 187.484 127.336L188.242 128.852C188.495 129.104 188.747 129.357 189 129.104L191.274 127.841C191.779 127.841 192.284 128.094 192.284 128.599Z"
          fill="#0E498F"
        />
      </svg>
      {mode === AppErrorMode.NotFound && (
        <Link href="/" prefetch={false}>
          <button className="inline-flex items-center justify-center rounded-md border border-transparent/0 bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm ring-offset-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            <Icon
              icon={Icons.INTELLPRO}
              size={"sm"}
              data-testid="search-results__loading"
            />
            <div className="pl-2">To Home</div>
          </button>
        </Link>
      )}
    </div>
  );
};
