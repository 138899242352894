"use client";

import { useEffect } from "react";
import { AppErrorMode, NextAppError } from "~/components-old/NextAppError";

export default function Error({ error }: ErrorProps) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return <NextAppError mode={AppErrorMode.Error} />;
}
